import React from 'react';
import { Link } from 'react-router-dom';
import { LoadingSpinner, AlertAux } from '../../components';
import { ralService } from '../../services'
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { RalsRows } from './RalsRows'
import TablePagination from '@material-ui/core/TablePagination';

import Grid from '@material-ui/core/Grid';

import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

class RalsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            rals: null,
            page: 0,
            rowsPerPage: 25,
            alertMessage: null,
            alertType: ""
        };
        this.handlerUpdateRows = this.handlerUpdateRows.bind(this)
        this.handleChangePage = this.handleChangePage.bind(this) 
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)  
    }

    handlerUpdateRows() {
        ralService.list()
        .then(rals => {
            const reverseRals = rals.reverse()
            this.setState({rals: reverseRals})  
        })
        .catch(errorMessage => {          
            this.setState({
                alertMessage: String(errorMessage),
                alertType: 'error',
            })     
        });
    }

    handleChangePage(event, newPage) {
        this.setState({page: newPage})
    };
    
    handleChangeRowsPerPage(event) {
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        })
    };

    componentDidMount(){
        ralService.list()
        .then(rals => {
            const reverseRals = rals.reverse()
            this.setState({rals: reverseRals})  
        })
        .catch(errorMessage => { 
            this.setState({
                alertMessage: String(errorMessage),
                alertType: 'error',
            })     
        });
    }

    render() {
        const path = this.props.match.path
        const rals = this.state.rals       
        const { t } = this.props;
        const { page, rowsPerPage, alertMessage, alertType } = this.state;
        const { classes } = this.props;

        return (
            <Grid item xs={12}>
                <Typography 
                    variant="h4" 
                    gutterBottom
                    className={classes.sec_title_default}
                    >
                    {t('rals')}
                </Typography>
                <Button
                    className={classes.button_default}
                    color="primary" 
                    linkButton={true} 
                    variant="contained" 
                    component={Link} to={`${path}/add`}>
                    {t('create')+" "+t('ral')}
                </Button>
                <TableContainer component={Paper}>
                    <Table className={Styles.table_default} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>{t('id')}</TableCell>
                                <TableCell align="right">{t('deliberated_by')}</TableCell>
                                <TableCell align="right">{t('status')}</TableCell>
                                <TableCell align="right">{t('license_plate')}</TableCell>
                                <TableCell align="right">{t('location')}</TableCell>
                                <TableCell align="right">{t('radius')}</TableCell>
                                <TableCell align="right">{t('owner')}</TableCell>
                                <TableCell align="right">{t('created')}</TableCell>
                                <TableCell align="right">{t('deliberated')}</TableCell>
                                <TableCell align="right">{t('liberation_period')}</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rals && rals
                                .sort((a, b) => (a.timestamp_created < b.timestamp_created) ? 1 : -1)
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(ral =>
                                <RalsRows 
                                    idx={ral.uuid}
                                    handlerUpdate={this.handlerUpdateRows} 
                                    props={this.props} 
                                    ral={ral}                                    
                                ></RalsRows>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {rals && 
                    <TablePagination
                        rowsPerPageOptions={[25, 50]}
                        component="div"
                        rowsPerPage={rowsPerPage}
                        count={rals.length}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        labelRowsPerPage={t('rows_per_page')}
                    />
                }
                {!rals &&
                    <LoadingSpinner></LoadingSpinner>
                }
                {rals && !rals.length &&
                    <Typography className={classes.sec_title_default}>{t('no_rals_to_display')}</Typography>
                }
                {alertMessage ?
                    <AlertAux message={alertMessage} type={alertType}></AlertAux>
                    : null
                } 
            </Grid>
        );
    }
}

RalsList.propTypes = {
    classes: PropTypes.object.isRequired,
};

const styledVehiclesList = withStyles(Styles)(RalsList)
const translatedRalsList = withTranslation()(styledVehiclesList);
export { translatedRalsList as RalsList };