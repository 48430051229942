import React from 'react';
import { LoadingSpinner,AlertAux } from '../../components';
import { withTranslation } from 'react-i18next';

import {
    Typography,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    TablePagination,
    Grid
} from '@material-ui/core';
import { connect } from 'react-redux';

import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Header } from '../../components/Header';
import { companiesService } from '../../services/companies_service';
import { CompaniesRow } from './CompaniesRow';

class CompaniesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: null,
            filteredCompanies: null,
            page: 0,
            rowsPerPage: 25,
            alertMessage: null,
            alertType: ''
        };
        this.findCompany = this.findCompany.bind(this);
        this.updateCompanies = this.updateCompanies.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)  
    }

    handleChangePage(event, newPage) {
        this.setState({page: newPage})
    };
    
    handleChangeRowsPerPage(event) {
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        })
    };

    async findCompany(text) {
        if (this.state.companies !== null) {
            await this.setState(oldState => {
                return {
                    ...oldState,
                    filteredCompanies: oldState.companies.filter((company) => company.company_identifier.includes(text)),
                }
            });
        }
        if (text === '') {
            await this.setState(oldState => {
                return {
                    ...oldState,
                    filteredCompanies: oldState.companies,
                }
            });
        }
    }
    
    updateCompanies() {
        companiesService.childrens_companies(this.props.user.company_identifier)
        .then(companies => {
            this.setState({companies: companies, filteredCompanies: companies})
            console.log(companies);
        }).catch(v => {
            this.setState({
                alertMessage: String(v), 
                alertType: 'error',
            })
        });
    }

    componentDidMount() {
        this.updateCompanies();
    }

    render() {
        const { t, classes } = this.props;
        const { page, rowsPerPage, alertMessage, alertType, companies, filteredCompanies } = this.state;
        
        return (
        <Grid item xs={12}>
            <Header
                props={ this.props }
                title='companies'
                phrase='company'
                search='search_company_idenfier'
                update={this.findCompany}
            />
            
            <TableContainer component={Paper}>
                <Table className={Styles.table_default} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>{t('uuid')}</TableCell>
                            <TableCell align="right">{t('company_identifier')}</TableCell>
                            <TableCell align="right">{t('company_name')}</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredCompanies && filteredCompanies
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(company => (
                            <CompaniesRow 
                                props={this.props} 
                                company={company}
                                key={ company.uuid }
                                update={ this.updateCompanies }
                            />
                        ))}
                    </TableBody>
                </Table>
                
            </TableContainer>
            {filteredCompanies &&
                <TablePagination
                    rowsPerPageOptions={[25, 50]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={filteredCompanies.length}
                    page={page}
                    labelRowsPerPage={t('rows_per_page')}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            }
            {!filteredCompanies &&
                <LoadingSpinner></LoadingSpinner>
            }
            {filteredCompanies && !filteredCompanies.length &&
                <Typography className={classes.sec_title_default}>{t('no_companies_to_display')}</Typography>
            }
            {alertMessage ?
                <AlertAux message={alertMessage} type={alertType}></AlertAux>
                : null
            } 
        </Grid>
        );
    }
}

CompaniesList.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedCompaniesList = connect(mapState)(CompaniesList);
const translatedCompaniesList = withTranslation()(connectedCompaniesList);
const styledCompaniesList = withStyles(Styles)(translatedCompaniesList)
export { styledCompaniesList as CompaniesList };