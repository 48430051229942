import React from 'react';
import { LoadingSpinner,AlertAux } from '../../components';
import { withTranslation } from 'react-i18next';

import {
    Typography,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Paper,
    TablePagination,
    Grid
} from '@material-ui/core';
import { connect } from 'react-redux';

import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Header } from '../../components/Header';
import { DevicesRow } from './DevicesRow';
import { deviceService } from '../../services';
import { companiesService } from '../../services/companies_service';

class DevicesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: null,
            page: 0,
            rowsPerPage: 5,
            alertMessage: null,
            alertType: '',
            companies: [],
        };  
        this.handleChangePage = this.handleChangePage.bind(this) 
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)  
    }

    async findDevice(text) {
        
    }

    handleChangePage(event, newPage) {
        this.setState({page: newPage})
    };

    handleChangeRowsPerPage(event) {
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        })
    };

    componentDidMount() {
        deviceService.list()
        .then(devices => {
            this.setState({devices: devices})  
        }).catch(v => {
            this.setState({
                alertMessage: String(v), 
                alertType: 'error',
            })
        });
        companiesService.childrens_companies()
        .then(companies => {
            this.setState({companies: companies})
        }).catch(v => {
            this.setState({
                alertMessage: String(v), 
                alertType: 'error',
            })
        });
    }

    render() {
        const { t, classes } = this.props;
        const { page, rowsPerPage, alertMessage, alertType, devices, companies } = this.state;
        
        return (
        <Grid item xs={12}>
            <Header
                props={ this.props }
                title='devices'
                phrase='device'
                search='search_device_by_obuid'
                update={this.findDevice}
                devlopment={true}
            />
            
            <TableContainer component={Paper}>
                <Table className={Styles.table_default} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>{t('obuid')}</TableCell>
                            <TableCell align="right">{t('type')}</TableCell>
                            <TableCell align="right">{t('device')}</TableCell>
                            <TableCell align="right">{t('battery_volts')}</TableCell>
                            <TableCell align="right">{t('last_read_datetime')}</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {devices && devices
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map(device => (
                            <DevicesRow
                                props={ this.props } 
                                device={ device }
                                companies={ companies }
                                key={ device.uuid }
                            />
                        ))}
                    </TableBody>
                </Table>
                
            </TableContainer>
            {devices &&
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={devices.length}
                    page={page}
                    labelRowsPerPage={t('rows_per_page')}
                    onPageChange={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            }
            {!devices &&
                <LoadingSpinner></LoadingSpinner>
            }
            {devices && !devices.length &&
                <Typography className={classes.sec_title_default}>{t('no_devices_to_display')}</Typography>
            }
            {alertMessage ?
                <AlertAux message={alertMessage} type={alertType}></AlertAux>
                : null
            } 
        </Grid>
        );
    }
}

DevicesList.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedDevicesList = connect(mapState)(DevicesList);
const translatedDevicesList = withTranslation()(connectedDevicesList);
const styledDevicesList = withStyles(Styles)(translatedDevicesList)
export { styledDevicesList as DevicesList };
