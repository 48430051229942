/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import {
    Button,
    Box,
    FormControl,
    Input,
    Typography
} from '@material-ui/core';

import { FaSistrix } from "react-icons/fa";

function Header({props, title, phrase, search, update, devlopment=false, company_name}) {
    const [value, setValue] = useState('');
    const { classes, t } = props;
    const path = props.match.path;

    async function updateValue(e) {
        await setValue(e.target.value);
    }
    useEffect(() => {
        update(value);
    }, [value])

    return (
        <div>
            <Typography 
                variant="h4" 
                gutterBottom
                className={classes.sec_title_default}
                >
                {t(title)}
            </Typography>
            {
                company_name &&
                <Typography 
                    variant="h6" 
                    gutterBottom
                    className={classes.sec_title_default}
                    >
                    {t('of_company') + ' ' + company_name}
                </Typography>
            }
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems='center'
            >
                {
                    !devlopment &&
                    <Button 
                        color="primary" 
                        linkButton={true} 
                        variant="contained"
                        className={classes.button_default}
                        component={Link} to={`${path}/add`}>
                        {t('add')+" "+t(phrase)}
                    </Button>
                }
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems='center'
                    width={18/100}
                    minWidth={220}
                >
                    {
                        !devlopment &&
                        <>
                            <FaSistrix
                                size={25}
                            />
                            <FormControl>
                                <Input
                                    type="text"
                                    placeholder={`${t(search)} ...`}
                                    disableUnderline
                                    value={value}
                                    onChange={updateValue}
                                />
                                    
                            </FormControl>
                        </>
                    }
                </Box>
            </Box>
        </div>
    );
}

export { Header };