import React from 'react';
import { LoadingSpinner } from '../../components';
import { withTranslation } from 'react-i18next';
import {
    TableCell,
    Paper,
    TableRow,
    Collapse,
    IconButton,
    DialogTitle,
    Dialog,
    Button,
    Box,
    DialogActions,
    Typography,
    FormControl,
    InputLabel,
    Input
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import BusinessIcon from '@material-ui/icons/Business';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import { connect } from 'react-redux';
import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { companiesService } from '../../services/companies_service';

class CompaniesRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            open: false,
            confirmationDialog: false,
            isDeleting: false,
            isEditing: false,
            isLock: false,
            isUnlock: false,
            company: this.props.company,
            name: this.props.company.company_name,
        };
        this.updateCompanyInfo = this.updateCompanyInfo.bind(this);
        this.handleChangeCompanyInfo = this.handleChangeCompanyInfo.bind(this);
    }

    async updateCompanyInfo(active) {
        const { t } = this.props;

        const { name, company } = this.state;
        if (name.length < 3) {
            this.setState((oldState) => {
                return {
                    ...oldState,
                    isEditing: !oldState.isEditing,
                    name: this.props.company.company_name,
                }
            })
            toast.error(t('must_contain') + ' 3 ' + t('character'));
            return;
        } else {
            const newCompany = {
                name: name,
                active: active,
                identifier: company.company_identifier,
            }
    
            return companiesService.update(newCompany)
                .then(v => {
                    if (active !== 1) {
                        toast.success(t('deleted'));
                        this.props.update();
                    } else {
                        toast.success(t('update_success'));
                        this.props.update();
                    }
                    this.setState((oldState) => {
                        return {
                            ...oldState,
                            isEditing: !oldState.isEditing,
                            name: name,
                            company: {
                                ...oldState.company,
                                company_name: name,
                                active: active
                            }
                        }
                    })
                })
                .catch(v => {          
                    toast.error(t('rejected'));
                });
        }
    }

    handleChangeCompanyInfo(event) {
        const { name, value } = event.target;
        this.setState(oldState => {
            return {
                ...oldState,
                [name]: value,
            }
        })
    }

    render() {
        const { t, classes, user } = this.props;
        const { 
            open,
            confirmationDialog,
            isDeleting,
            isEditing,
            company,
            name,
            isLock,
            isUnlock
        } = this.state;

        return (
            <>
                <TableRow
                    key={company.uuid}
                >
                    <TableCell
                        className={
                            user.company_identifier.toString() === company.company_identifier.toString() ?
                            classes.company_row_cel :
                            ''
                        }
                    >
                        <IconButton
                            aria-label="expand row"
                            size="small" 
                            onClick={() => this.setState({open : !open})}
                        >
                            {open ? 
                                <KeyboardArrowUpIcon
                                    className={
                                        user.company_identifier.toString() === company.company_identifier.toString() ?
                                        classes.company_row_cel :
                                        ''
                                    }
                                /> : 
                                <KeyboardArrowDownIcon
                                    className={
                                        user.company_identifier.toString() === company.company_identifier.toString() ?
                                        classes.company_row_cel :
                                        ''
                                    }
                                />}
                        </IconButton>
                    </TableCell>
                    <TableCell
                        component="th"
                        scope="row"
                        className={
                            user.company_identifier.toString() === company.company_identifier.toString() ?
                            classes.company_row_cel :
                            ''
                        }
                    >
                        {company.uuid}
                    </TableCell>
                    <TableCell
                        className={
                            user.company_identifier.toString() === company.company_identifier.toString() ?
                            classes.company_row_cel :
                            ''
                        }
                        align="right"
                    >
                        {company.company_identifier}
                    </TableCell>
                    <TableCell
                        align="right"
                        className={
                            user.company_identifier.toString() === company.company_identifier.toString() ?
                            classes.company_row_cel :
                            ''
                        }
                    >
                        {company.company_name}
                    </TableCell>
                    <TableCell
                        className={
                            user.company_identifier.toString() === company.company_identifier.toString() ?
                            classes.company_row_cel :
                            ''
                        }
                    >
                        {
                            user.company_identifier.toString() !== company.company_identifier.toString() ?
                            (
                                company.active.toString() === '1' ?
                                <IconButton
                                    onClick={() => this.setState({confirmationDialog: true, isLock: true})}
                                    linkButton={true} 
                                    variant="contained"
                                    className={classes.icon_default}
                                    >
                                    <LockOpen />
                                </IconButton>
                                :
                                <IconButton
                                onClick={() => this.setState({confirmationDialog: true, isUnlock: true})}
                                linkButton={true} 
                                variant="contained"
                                className={classes.icon_red_default}
                                >
                                {isDeleting 
                                    ? <LoadingSpinner></LoadingSpinner>
                                    : <Lock/>
                                }
                            </IconButton>
                            )
                            :
                            <>
                            <IconButton
                                linkButton={true} 
                                variant="contained"
                                >
                                {isDeleting 
                                    ? <LoadingSpinner></LoadingSpinner>
                                    : <BusinessIcon style={{color:'#FFF'}}/>
                                }
                            </IconButton>
                            </>
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Paper elevation={2}>
                                <Box padding="20px">
                                    <div>
                                        <Typography
                                            className={classes.sec_title_default}
                                            variant="h5"
                                            gutterBottom
                                            component="div"
                                        >
                                            {t('info')}
                                        </Typography>
                                    </div>
                                    <div>
                                        <form>
                                            <div>
                                                <FormControl>
                                                    <InputLabel>{t('name')}</InputLabel>
                                                    <Input 
                                                        type="text"
                                                        value={ name }
                                                        name="name"
                                                        disabled={!isEditing}
                                                        onChange={this.handleChangeCompanyInfo}/>
                                                </FormControl>
                                                {   name &&
                                                    <Typography 
                                                        variant="caption" 
                                                        display="block"
                                                        className="form-text text-muted"
                                                    >
                                                        {t('max_of_characters')} 20 {t('character').toLowerCase()}
                                                    </Typography>
                                                    }
                                            </div>
                                            <div>
                                                {
                                                    isEditing &&
                                                    <>
                                                        <Button
                                                            className={classes.button_default}
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => {
                                                                this.updateCompanyInfo(1);
                                                            }}
                                                        >
                                                            { t('save') + ' ' + t('company') }
                                                        </Button>
                                                        <Button
                                                            className={classes.button_red_default}
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => {
                                                                this.setState((oldState) => {
                                                                    return {
                                                                        ...oldState,
                                                                        isEditing: !oldState.isEditing,
                                                                        name: this.props.company.company_name
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            { t('cancel') }
                                                        </Button>
                                                    </>
                                                }
                                            </div>
                                        </form>
                                    </div>
                                </Box>
                            </Paper>
                            {
                                !isEditing &&
                                <Button
                                    className={classes.button_edit_user}
                                    onClick={() => this.setState((oldState) => {
                                        return {
                                            ...oldState,
                                            isEditing: !oldState.isEditing,
                                        }
                                    })}
                                >
                                    {t('edit') + ' ' + t('company')}
                                </Button>
                            }
                        </Collapse>
                    </TableCell>
                </TableRow>

            <Dialog 
                onClose={() => this.setState({confirmationDialog: false, isLock: false, isUnlock: false})} 
                aria-labelledby="simple-dialog-title" 
                open={confirmationDialog}
                fullWidth='sm'
                maxWidth='sm'
            >
                    <DialogTitle 
                        id="simple-dialog-title"
                        className={classes.sec_title_default}
                    >
                        {
                            isLock &&
                            t('confirm_company_block')
                        }
                        {
                            isUnlock &&
                            t('confirm_company_unlock')
                        }
                    </DialogTitle>
                    <DialogActions>
                        <Button 
                            color="primary"
                            variant="contained" 
                            className={classes.button_green_default}
                            onClick={() => {
                                isLock && this.updateCompanyInfo(0);
                                isUnlock && this.updateCompanyInfo(1);
                                this.setState({confirmationDialog: false});
                            }}
                            >
                            {t('yes')}
                        </Button>
                        <Button 
                            onClick={() => this.setState({confirmationDialog: false})}
                            color="primary" 
                            variant="contained" 
                            autoFocus
                            className={classes.button_red_default}
                            >
                            {t('no')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}

CompaniesRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedCompaniesRow = connect(mapState)(CompaniesRow);
const translatedCompaniesRow = withTranslation()(connectedCompaniesRow);
const styledCompaniesRow = withStyles(Styles)(translatedCompaniesRow)
export { styledCompaniesRow as CompaniesRow };