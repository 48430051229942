import React from 'react';
import { LoadingSpinner,AlertAux } from '../../components';
import { vehicleService } from '../../services'
import { withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { VehiclesRows } from './VehiclesRows';
import TablePagination from '@material-ui/core/TablePagination';

import Grid from '@material-ui/core/Grid';

import { Styles } from '../../styles'
import { withStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Header } from '../../components/Header';

class VehiclesList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {      
            vehicles: null,
            filteredVehicles: [],
            page: 0,
            rowsPerPage: 25,
            alertMessage: null,
            alertType: '',
            plate: '',
        };  
        this.handleChangePage = this.handleChangePage.bind(this)
        this.handlerUpdateRows = this.handlerUpdateRows.bind(this) 
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)  
        this.filterVehicles = this.filterVehicles.bind(this) 
        this.updatePlate = this.updatePlate.bind(this) 
    }

    updatePlate(value) {
        const { vehicles } = this.state;
        if (value.length >= 0 && value.length < 8) {
            this.setState({plate: value.toUpperCase()});
            this.filterVehicles(value.toUpperCase());
        } else {
            if (value.length === 0)
                this.setState({filteredVehicles: vehicles})
            return;
        }
    }

    filterVehicles(plate) {
        if (this.state.vehicles !== null) {
            this.setState((oldState) => {
                return {
                    ...oldState,
                    filteredVehicles: oldState.vehicles.filter((item) => {
                        return item.license_plate.includes(plate);
                    })
                }
            })
        }
    }

    componentDidMount(){
        vehicleService.list()
        .then(vehicles => {
            this.setState({vehicles: vehicles})
            this.setState({filteredVehicles: vehicles})
        }).catch(v => {
            this.setState({
                alertMessage: String(v), 
                alertType: 'error',

            })
        });
    }

    handlerUpdateRows() {
        vehicleService.list()
        .then(vehicles => {
            this.setState({vehicles: vehicles})  
        }).catch(v => {
            this.setState({
                alertMessage: String(v), 
                alertType: 'error',

            })
        });
    }

    handleChangePage(event, newPage) {
        this.setState({page: newPage})
    };
    
    handleChangeRowsPerPage(event) {
        this.setState({
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        })
    };


    render() {
        const vehicles = this.state.filteredVehicles
        const { t } = this.props;
        const { page, rowsPerPage, alertMessage, alertType } = this.state;
        const { classes } = this.props;

        // vehicle
        /*
        {
            "uuid": "88f56636-04d3-11eb-941d-02420a002d08",
            "license_plate": "AAA0001",
            "type": "truck",
            "city": "São Paulo",
            "state": "SP",
            "country": "BR",
            "company_identifier": "65575",
            "device_list": [
                {
                    "type": "ssl",
                    "obuid": "1870624410",
                    "alias": "000D6F7F769A"
                }
            ]
        },
        */
        return (
        <Grid item xs={12}>
            <Header 
                props={ this.props }
                title='vehicles'
                phrase='username'
                search='searchByPlate'
                update={this.updatePlate}
            />
            
            <TableContainer component={Paper}>
                <Table className={Styles.table_default} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell>{t('id')}</TableCell>
                            <TableCell align="right">{t('license_plate')}</TableCell>
                            <TableCell align="right">{t('type')}</TableCell>
                            <TableCell align="right">{t('company')}</TableCell>
                            <TableCell align="right">{t('location')}</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {vehicles && vehicles
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map(vehicle => (
                        <VehiclesRows 
                            props={this.props} 
                            vehicle={vehicle}
                            handlerUpdate={this.handlerUpdateRows}
                            key={ vehicle.license_plate }

                        ></VehiclesRows>
                    ))}
                    </TableBody>
                </Table>
                
            </TableContainer>
            {vehicles && 
                <TablePagination
                    rowsPerPageOptions={[25, 50]}
                    component="div"
                    rowsPerPage={rowsPerPage}
                    count={vehicles.length}
                    page={page}
                    labelRowsPerPage={t('rows_per_page')}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            }
            {!vehicles &&
                <LoadingSpinner></LoadingSpinner>
            }
            {vehicles && !vehicles.length &&
                <Typography className={classes.sec_title_default}>{t('no_vehicles_to_display')}</Typography>
            }
            {alertMessage ?
                <AlertAux message={alertMessage} type={alertType}></AlertAux>
                : null
            } 
        </Grid>
        );
    }
}

VehiclesList.propTypes = {
    classes: PropTypes.object.isRequired,
};


function mapState(state) {
    const { authentication } = state;
    const { user } = authentication;
    return { user };
}

const connectedVehiclesList = connect(mapState)(VehiclesList);
const translatedVehiclesList = withTranslation()(connectedVehiclesList);
const styledVehiclesList = withStyles(Styles)(translatedVehiclesList)
export { styledVehiclesList as VehiclesList };